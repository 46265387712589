@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap');
.outercontainer{
  padding: 90px 100px;  
}

.innerContainer{
  padding: 50px 200px;
  border: 3px solid rgba(255, 255, 255, 0.226);
  border-radius: 20px;
  background-color: white;
}

.innerContainer h2{
  color: #4e54c8;
  font-size: 55px;
  font-weight: 600;
  font-family: 'Montserrat';
}

.innerContainer p{
  font-family: 'Montserrat';

}

.dropbox{
  background-color: whitesmoke;
  border: 2px dashed #03a8f4;
  border-radius: 20px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
}

.dropbox .btn{
  border-radius: 0px;
  color: white;
  border: none;
  font-size: 24px;
  margin: 0px;
  font-family: 'Montserrat';
  background-color: #03a8f4;
}



.loader {
  position: relative;
  display: block;
  height: 50px;
  width: 50px;
  transition: all .3s;
  transition-timing-function: ease-in;
  animation: spinHourglas 1000ms infinite;
}
.loader::after{
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: 0 0;
  border-width: 0.585em;
  border-color: #4e54c8 transparent;
  border-radius: 50%;
  border-style: solid;
}
@keyframes spinHourglas {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(.55,.055,.675,.19);
  }
  50% {
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(.215,.61,.355,1);
  }
  100% {
    transform: rotate(360deg);
  }
}